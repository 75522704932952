import { useEffect, useRef, useState } from 'react';

declare interface TimerProps {
  timerExpirationDate: Date | null;
  onTimeout?: () => void;
}

const calculateTimeLeft = (props: TimerProps, intervalId: NodeJS.Timeout | null) => {
  if (!props.timerExpirationDate) {
    return '';
  }

  const today = new Date();
  let diffMilliseconds = new Date(props.timerExpirationDate?.toISOString()).getTime() - today?.getTime();
  diffMilliseconds = diffMilliseconds < 0
    ? 0
    : diffMilliseconds;

  const diffHours = Math.floor(diffMilliseconds / 3600000);
  const diffMinutes = Math.floor((diffMilliseconds % 3600000) / 60000);
  const diffSeconds = Math.floor(((diffMilliseconds % 3600000) % 60000) / 1000);

  if (diffMinutes <= 0 && diffMilliseconds <= 0) {
    intervalId && clearInterval(intervalId);
    if (props.onTimeout) {
      props.onTimeout();
    }
    return '';
  }

  return diffHours === 0 ?
    `${String(diffMinutes).padStart(2, '0')}:${String(diffSeconds).padStart(2, '0')}` :
    `${String(diffHours).padStart(2, '0')}:${String(diffMinutes).padStart(2, '0')}:${String(diffSeconds).padStart(2, '0')}`;
};

export const useTimer = (props: TimerProps) => {
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(props, intervalId.current));

  useEffect(() => {
    if (!props.timerExpirationDate) {
      intervalId.current = null;
      return;
    }

    intervalId.current = setInterval(() => setTimeLeft(calculateTimeLeft(props, intervalId.current)), 250);

    return () => clearInterval(intervalId.current);
  }, [props.timerExpirationDate?.getTime()]);

  return timeLeft;
};
