import { useState, useEffect } from 'react';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/empty';

import { useAjaxResponse } from 'app/hooks/ajax/use-ajax-response.hook';
import { AvailableLoadDetail, AvailableLoadError, AvailableLoadNotFound } from 'shared/models/loads/available-load-detail.model';
import { ShipmentsRepository } from 'app/repositories/shipments.repository';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';

interface UseAvailableLoadDetailHook {
  (loadId: number, loaderName?: string): [AvailableLoadDetail, AvailableLoadNotFound | AvailableLoadError, (silent?: boolean) => void];
}

export const useAvailableLoadDetails: UseAvailableLoadDetailHook = (loadId, loaderName = null) => {
  const [error, setError] = useState<AvailableLoadNotFound | AvailableLoadError>(null);
  const repo = useRepository(ShipmentsRepository);

  useEffect(() => {
    setError(e => null);
  }, [loadId]);

  const [load, refresh] = useAjaxResponse(
    () => {
      if (!loadId) {
        return Observable.empty();
      }

      return repo.getLoad(loadId).catch(err => {
        const errorResult = err.status === 404 ? new AvailableLoadNotFound(loadId) : new AvailableLoadError(err, loadId);
        setError(errorResult);
        return Observable.empty();
      });
    },
    [loadId], { loaderName }
  );

  return [load, error, refresh];
};
